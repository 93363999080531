/* eslint-disable no-unused-vars */
import { useRef } from "react";
import LogoS from "../../../assets/images/gazebodeals1.png";
import "./index.scss";

const Logo = () => {
  const bgRef = useRef();
  const outlineLogoRef = useRef();
  const solidLogoRef = useRef();

  return (
    <div className="logo-container pulse" ref={bgRef}>
      <img
        className="solid-logo"
        ref={solidLogoRef}
        src={LogoS}
        alt="T̶̳͔̎̓̒̐͘̚A̸̢̗̫̯̪̞̭̺͎̰̲͈͚̯͗͑͜Ķ̴̘̳͈̞̻̼̐͘̚ͅȨ̸̧̭̱̫̗͇͇̙̝̖̅̆̈́̽͆̀̕ ̴͔͔̤̅͌͋̒͋͒͒̉́͝T̵̝̞̜͚͙̞̫͙̱̪̗͓͕̗̜͒̏̂́̊̓̓̐͑̉͘͘H̸̡̩̜̱̤͚̃̓͛̐͑́͑̋͒͑̑̈́͝E̷̱͍̬͎͋͛̋͗̆ ̵̛̼͉͙͕͕̯̰̺̹̰͒͊̽͐̃͊̏̋̓̐͘͘̕͝P̷͎̲͈͈̙̙̗̞̞̂͛̎͛͂̓̈́͛͂̌͛͘̚͘͘I̸̢̨̧͖͚̲̼͍̟̻̗͐̎̐̊͜ͅL̴̫͎̱̻͚̝͌́̂̍̈́̍̿̚͝Ľ̸̨̧̡͍̠͕̦͙͈̥͕͚͖́̑̆̉̆̅̈̓͊́ͅS̸̙̞̺͕̞͉͉͓̝̜̳̙̟͔͌͂͒̈́̊̕͝
̷̬͔̱͕̔"
      />
    </div>
  );
};

export default Logo;
