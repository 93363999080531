/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
  faPython,
} from "@fortawesome/free-brands-svg-icons";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import itme from "../../assets/images/metransparent.png";
import "./index.scss";

const About = () => {
  const aboutArray = "About Me:".split("");

  const [letterClass, setLetterClass] = useState("text-animate");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={aboutArray}
              idx={15}
            />
          </h1>
          <p>
            I am a self-taught music producer/audio engineer with nearly a
            decade of experience. I specialize in producing electronic genres
            and recording/mixing vocals, but I'm always exploring different
            genres and practicing new techniques. I also have a background in
            live sound and performance as a death metal vocalist.
          </p>
          <p>
            I am a passionate lifelong learner of technology and am currently
            studying full-stack web development. This website is currently all I
            have to show for that, but I never stop learning. There is no end to
            my programming education in sight, so keep an eye on me.
          </p>
          <p>
            In my free time I play/modify fantasy RPGs, tinker with the Adobe
            Creative Cloud, and research a lot of ancient and prehistory. I am
            based in Chicago where I live with my girlfriend and two cats,
            Beatrice and Charlie.
          </p>
        </div>
      </div>
      
      
      <div className='pushright'>
    <img src={itme} alt="" width="100%" id="zebo" /> </div>
      
      
      
      
      <Loader type="pacman" />
    </>
  );
};

export default About;
