import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import WordCloud from './wordcloud'

const Skills = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const skillsArray = 'Skills'.split('')

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div className="container skills-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={skillsArray}
              idx={15}
            />
            <br />
          </h1>
          <p>
            
          </p>
          <p>
            I have a unique and well-rounded skillset that spans across several different types of technologies and arts.</p>
            
           <p> My thorough understanding of the fundamentals of digital audio production allow me to operate nearly any type of music software. I can compose multiple genres and have experience in every step of the song-making process.</p> 
             
             <p>My audio engineering skills expand beyond just music and I would love the opportunity to mix for tv/film or podcasts.</p>
            
           <p> I spent 7 years studying and performing stand-up and sketch comedy. Though I no longer perform, I retain a unique comedic perspective that I apply to any project that calls for it.</p>
            
            <p>This website is currently what I have to show for my skills as a developer (basic full-stack web development), but I never stop learning. Keep an eye on me.
          </p>
        </div>

        <div className="tagcloud-wrap">
          <WordCloud />
        </div>
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default Skills
